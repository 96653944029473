import { msalInstance } from "@/modules/msal/msalAuthConfig"
import { Role } from "./Role"

export function isGlobalAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.GlobalAdministrator)
  }
  return false
}

export function isInvestor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.Investor)
  }
  return false
}

export function isBackOfficeUser() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isExchangeRatesEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.GlobalAdministrator)
  }
  return false
}

export function isFundEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isCountryEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.GlobalAdministrator)
  }
  return false
}

export function isFundManagerAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isFundReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isFundTransactionAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isFundPerformanceAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isFundTransactionReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isInvestmentReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isInvestmentAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isInvestmentTransactionReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isInvestmentTransactionAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isBankAccountAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.GlobalAdministrator)
  }
  return false
}

export function isFairValuesReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isFairValuesAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isFundBankAccountReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isPortfolioInvestmentBankAccountReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isReportingPeriodReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isReportingPeriodAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isExpenseReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isExpenseAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isInvestorReportAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isContactReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isContactAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isLimitedPartnerReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isLimitedPartnerAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isOnboardingReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isOnboardingEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isCapitalCallReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isCapitalCallEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}

export function isCapitalCallAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isClosingReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}

export function isClosingEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isOnboardingAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isAdministrativeAgent() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}
export function isInvoiceAdmin() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}
export function isInvoiceEditor() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User)
  }
  return false
}
export function isInvoiceReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator) || userRoles.includes(Role.User) || userRoles.includes(Role.AdministrativeAgent)
  }
  return false
}
export function isPortalReader() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return (
      userRoles.includes(Role.Investor) ||
      userRoles.includes(Role.TenantAdministrator) ||
      userRoles.includes(Role.AdministrativeAgent) ||
      userRoles.includes(Role.User)
    )
  }
  return false
}

export function isTenantAdministrator() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.TenantAdministrator)
  }
  return false
}

export function isUserAndAgent() {
  const account = msalInstance.getActiveAccount()
  const userRoles = account?.idTokenClaims?.roles
  if (account && userRoles) {
    return userRoles.includes(Role.AdministrativeAgent) || userRoles.includes(Role.User)
  }
  return false
}