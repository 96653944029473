export class AcConstants {
  public static readonly dateFormat = "{0:dd/MM/yyyy}"
  public static readonly dateFormatString = "dd/MM/yyyy"
  public static readonly dateFormatCulture = "en-GB"
  public static readonly dateFormatCultureUs = "en-US"
  public static readonly sixDigitsNumberFormat = "{0:##,#0.000000;(##,#0.000000)}"
  public static readonly twoDigitsNumberFormat = "{0:##,#0.00;(##,#0.00)}"
  public static readonly oneDigitsNumberFormat = "{0:##,#0.0;(##,#0.0)}"
  public static readonly integerNumberFormat = "{0:##,#0;(##,#0)}"
  public static readonly fourDigitsPercentNumberFormat = "{0:##,#0.0000;(##,#0.0000)}%"
  public static readonly twoDigitsPercentNumberFormat = "{0:##,#0.00;(##,#0.00)}%"
  public static readonly twoDigitsCoefficientNumberFormat = "x{0:##,#0.00;(##,#0.00)}"
  public static readonly numberValueLimitationLT = 9999999.99
  public static readonly numberValueLimitationGT = -9999999.99
  public static readonly cookieNameGuid = "agst-0nbr-id"
  public static readonly cookieExpirationInDays = 1
  public static readonly defaultDecimalPlaces = 0
  public static readonly defaultDecimalPlacesPercentage = 2
  public static readonly defaultDecimalPlacesCoefficient = 2
}
